<template>
  <div key="searchKey">
    <div v-if="content !== ''">
      <div class="div_title">
        <context :context=cate1 />
        <policies/>
      </div>
      <div id="login-section" class="card main no-result" > 
        <span>{{ msg }}</span>
      </div>
    </div>
    <div class="div_title">
      <context :context=cate2 />
    </div>
    <div v-for="category in keywordList" :key="category.type" class="card main">
      <h3>{{ getCategoryTitle(category.type) }}</h3>
      <ul>
        <li v-for="item in category.items" :key="item.TITLE" class="">
          
          <p v-if="category.type === 'policy'">{{ item.CONTENT }}</p>
          <p v-else ><strong>{{ item.TITLE }} : </strong> <a :href="item.CONTENT" target="_blank">{{ item.CONTENT }}</a></p>
        </li>
      </ul>
    </div>
  </div>
  <div class="ad-container" style="width:100%;text-align: center;">
    <Adsense
      data-ad-client="ca-pub-6699354447815429" 
      data-ad-slot="8230887469"
      data-ad-format="horizontal"
      data-full-width-responsive="true">
    </Adsense>
  </div>
</template>

<script>
import { ref, inject } from 'vue'
import ssr from '@/components/common/ssr'
import context from '@/components/common/Context.vue'
import policies from '@/components/common/policies.vue'
import router from '@/router'

export default {
  setup() {
    const setMeta = inject('setMeta')
    const title = 'GEEK9 > 검색'
    const keywords = '직구,해외직구,구매대행,배송대행,배대지,geek9,국내,중고,온라인,거래,중고거래'
    const description = 'GEEK9 서비스 검색'
    const geek9Url = ssr.GEEK9_URL + 'search'
    setMeta({
      title: title,
      keywords: keywords,
      description: description,
      'og:title': title,
      'og:description': description,
      'og:image': 'https://geek9.kr/img/logo_big.e0186dc8.jpg',
      'og:url': geek9Url,
      'canonical': geek9Url
    })
    const cate1 = ref([
      { label: '결과' },
    ])
    const cate2 = ref([
      { label: '관련 내용' },
    ])
    const addJsonLd = () => {
      let flag = true
      document.querySelectorAll('script').forEach(el => {
        if (el.getAttribute('type') === 'application/ld+json' ) {
          flag = false
          return
        }
      })
      if(flag === true) {
        const script = document.createElement('script')
        script.setAttribute('type', 'application/ld+json')
        script.textContent = JSON.stringify(
          {
            "@context": "https://schema.org",
            "@graph": [
              ssr.getWebPage(geek9Url, title, ssr.GEEK9_URL, description),
            ]
          }
        )
        document.head.appendChild(script)
      }
    }
    const getCategoryTitle = (type) => {
      switch(type) {
        case 'policy':
          return '정책';
        case 'chatbot-recm':
          return '챗봇추천';
        default:
          return type;
      }
    }
    return {
      cate1, cate2, addJsonLd, getCategoryTitle
    }
  },
  mounted() {
    this.addJsonLd()
    this.emitter.on('searchReload', this.reload)
    this.api.setPage('search')
    console.log(this.api.getCookies('page'))
    this.content = this.$route.params.content
    if(this.content === undefined)
      router.push({name:"home"})
    else {
      this.msg = this.$route.params.msg
      this.page = this.$route.params.page
      this.keywordsSearch()
    }
    window.scrollTo(0, 0)
  },
  unmounted() {
    this.emitter.off('searchReload', this.reload)
  },
  components: {
    context, policies
  },
  data() { 
    return { 
      keywords:{},
      keywordList:[],
      msg:'',
      page:'',
      content:'',
      searchKey:-1,
    }
  },
  methods: {
    async keywordsSearch() {
      let tmpMap = new Map()
      await this.api.axiosGql('SELECT_KEYWORDS', {type:'SELECT_KEYWORDS'})
      .then( async (res) => {
        const keywords = res.data
        this.keywordList = await this.extractKeyword(keywords)
        // this.keywords = res.data
        // for(var i=0; i<keywords.length; i++) {
        //   const keyword = keywords[i]
        //   const key = keyword.type
        //   const items = keyword.items
        //   for(var j=0; j<items.length; j++) {
        //     const item = items[j]
        //     if(tmpMap.has(key)) {
        //       let valueList = tmpMap.get(key)
        //       valueList.push({title:item.TITLE, content:item.CONTENT})
        //       tmpMap.set(key, valueList)
        //     } else tmpMap.set(key, [{title:item.TITLE, content:item.CONTENT}])
        //   }
        // }
      })
      this.keywordsMap = tmpMap
    },
    async extractKeyword(dataArray) {
      const resultArray = []

      dataArray.forEach(category => {
        const matchedItems = []
        category.items.forEach(item => {
          if (this.content.includes(item.TITLE)) {
            matchedItems.push({
              TITLE: item.TITLE,
              CONTENT: item.CONTENT
            })
          }
        })
        if (matchedItems.length > 0) {
          resultArray.push({
            type: category.type,
            items: matchedItems
          })
        }
      })
      return resultArray
    },
    reload(flag) {
      if(flag === 'dashboard') {
        this.searchKey++
        this.$emit("setMenu", {page:'search'})
        }
    },
  }
}
</script>
<style scoped>
  #login-section {
    font-size:20px;
    font-weight:bold;
  }
  /* .category {
    margin-bottom: 20px;
  }

  .item {
    margin-bottom: 10px;
  } */

  /* ul {
    list-style-type: none;
    padding-left: 0;
  } */
</style>
